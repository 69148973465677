/*
* Mixin for easy media queries
* @include bp(tablet-only) { SCSS here };
* Default css is for mobile screens
*/

@font-face {
  font-family: "Roobert";
  font-weight: 400;
  font-style: normal;
  src: url("../assets/fonts/roobert/Roobert-Regular.eot");

  src: url("../assets/fonts/roobert/Roobert-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/roobert/Roobert-Regular.woff") format("woff"),
    url("../assets/fonts/roobert/Roobert-Regular.ttf") format("truetype"),
    url("../assets/fonts/roobert/Roobert-Regular.svg#Roobert") format("svg");
}

@font-face {
  font-family: "Roobert";
  font-weight: 500;
  font-style: normal;
  src: url("../assets/fonts/roobert/Roobert-Medium.eot");
  src: url("../assets/fonts/roobert/Roobert-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/roobert/Roobert-Medium.woff") format("woff"),
    url("../assets/fonts/roobert/Roobert-Medium.ttf") format("truetype"),
    url("../assets/fonts/roobert/Roobert-Medium.svg#Roobert") format("svg");
}

@font-face {
  font-family: "Roobert";
  font-weight: 600;
  font-style: normal;
  src: url("../assets/fonts/roobert/Roobert-SemiBold.eot");
  src: url("../assets/fonts/roobert/Roobert-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/roobert/Roobert-SemiBold.woff") format("woff"),
    url("../assets/fonts/roobert/Roobert-SemiBold.ttf") format("truetype"),
    url("../assets/fonts/roobert/Roobert-SemiBold.svg#Roobert") format("svg");
}

@font-face {
  font-family: "Roobert";
  font-weight: 700;
  font-style: normal;
  src: url("../assets/fonts/roobert/Roobert-Bold.eot");
  src: url("../assets/fonts/roobert/Roobert-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/roobert/Roobert-Bold.woff") format("woff"),
    url("../assets/fonts/roobert/Roobert-Bold.ttf") format("truetype"),
    url("../assets/fonts/roobert/Roobert-Bold.svg#Roobert") format("svg");
}

@font-face {
  font-family: "Aeonik Pro";
  font-weight: 300;
  font-style: normal;
  src: url("../assets/fonts/aeonikpro/AeonikPro-Light.eot");
  src: url("../assets/fonts/aeonikpro/AeonikPro-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/aeonikpro/AeonikPro-Light.woff") format("woff"),
    url("../assets/fonts/aeonikpro/AeonikPro-Light.ttf") format("truetype"),
    url("../assets/fonts/aeonikpro/AeonikPro-Light.svg#Aeonik Pro")
      format("svg");
}

@font-face {
  font-family: "Aeonik Pro";
  font-weight: 400;
  font-style: normal;
  src: url("../assets/fonts/aeonikpro/AeonikPro-Regular.eot");
  src: url("../assets/fonts/aeonikpro/AeonikPro-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/aeonikpro/AeonikPro-Regular.woff") format("woff"),
    url("../assets/fonts/aeonikpro/AeonikPro-Regular.ttf") format("truetype"),
    url("../assets/fonts/aeonikpro/AeonikPro-Regular.svg#Aeonik Pro")
      format("svg");
}

@font-face {
  font-family: "Aeonik Pro";
  font-weight: 500;
  font-style: normal;
  src: url("../assets/fonts/aeonikpro/AeonikPro-Medium.eot");
  src: url("../assets/fonts/aeonikpro/AeonikPro-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/aeonikpro/AeonikPro-Medium.woff") format("woff"),
    url("../assets/fonts/aeonikpro/AeonikPro-Medium.ttf") format("truetype"),
    url("../assets/fonts/aeonikpro/AeonikPro-Medium.svg#Aeonik Pro")
      format("svg");
}

@font-face {
  font-family: "Aeonik Pro";
  font-weight: 700;
  font-style: normal;
  src: url("../assets/fonts/aeonikpro/AeonikPro-Bold.eot");
  src: url("../assets/fonts/aeonikpro/AeonikPro-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/aeonikpro/AeonikPro-Bold.woff") format("woff"),
    url("../assets/fonts/aeonikpro/AeonikPro-Bold.ttf") format("truetype"),
    url("../assets/fonts/aeonikpro/AeonikPro-Bold.svg#Aeonik Pro") format("svg");
}

@font-face {
  font-family: "icomoon";
  font-weight: 400;
  font-style: normal;
  src: url("../assets/fonts/icomoon/icomoon.eot");
  src: url("../assets/fonts/icomoon/icomoon.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/icomoon/icomoon.woff") format("woff"),
    url("../assets/fonts/icomoon/icomoon.ttf") format("truetype"),
    url("../assets/fonts/icomoon/icomoon.svg#icomoon") format("svg");
}

/*Sass Variables---------------------------------------------------------------------------*/
:root {
  --bs-body-bg: #ffffff;
}
/*=Global
---------------------------------------*/
html {
  scroll-behavior: smooth;
}

.bootstrap-scope {
  @import "bootstrap/scss/bootstrap";
}
div#landing {
  .body {
    line-height: 1.5;
    background-color: #eeeeee;
    font-weight: normal;
    color: #111010;
    font-family: "Roobert", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  @-ms-viewport {
    width: auto;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 1.25rem; //2rem * 0.625
    line-height: 1;
    font-weight: 500;
    color: #111010;
  }

  h1 a:not(:hover),
  h2 a:not(:hover),
  h3 a:not(:hover),
  h4 a:not(:hover),
  h5 a:not(:hover),
  h6 a:not(:hover) {
    color: inherit;
  }

  h1 strong,
  h2 strong,
  h3 strong,
  h4 strong,
  h5 strong,
  h6 strong {
    color: #00b0a8;
    display: block;
    font-weight: inherit;
  }

  h1 {
    font-size: 2.5rem; // 4rem * 0.625
    margin-bottom: 0.9375rem; // 1.5rem * 0.625
  }

  h2 {
    font-size: 1.875rem; // 3rem * 0.625
    line-height: 1.1;
    margin-bottom: 0.9375rem; // 1.5rem * 0.625
  }

  h3 {
    font-size: 1.5rem; // 2.4rem * 0.625
    line-height: 1.1;
  }

  h4 {
    font-size: 1.25rem; // 2rem * 0.625
    line-height: 1.2;
    margin-bottom: 0.75rem; // 1.2rem * 0.625
  }

  h5 {
    font-size: 1rem; // 1.6rem * 0.625
  }

  h6 {
    font-size: 0.75rem; // 1.2rem * 0.625
  }

  p {
    margin: 0 0 1.5625rem 0; //2.5rem * 0.625
  }

  p a {
    color: #00b0a8;
    text-decoration: underline;
  }

  b,
  strong {
    font-weight: bold;
  }

  ul {
    padding: 0 0 0 17px; //17px * 0.625
    margin: 0 0 1.5625rem; //2.5rem * 0.625
  }

  img {
    width: auto;
    height: auto;
    max-width: 100%;
  }

  ::-webkit-input-placeholder {
    color: #707070;
    font-size: 0.875rem; //1.4rem * 0.625
    line-height: 1.2;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    color: #707070;
    font-size: 1rem; //1.6rem * 0.625
    opacity: 0.75;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    color: #707070;
    font-size: 1rem; //1.6rem * 0.625
    opacity: 0.75;
  }

  :-ms-input-placeholder {
    color: #707070;
    font-size: 1rem; //1.6rem * 0.625
    opacity: 0.75;
  }

  input:focus {
    outline: none;
  }

  .form-control {
    height: 3.125rem; //5rem * 0.625
    background-color: transparent;
    border-radius: 0.625rem; //1rem * 0.625
    border: 1px solid rgba(17, 16, 16, 0.25);
    font-size: 1rem; //1.6rem * 0.625
    line-height: 1;
    font-weight: normal;
    color: #111010;
    padding: 0.3125rem 0.9375rem; //0.5rem * 0.625, 1.5rem * 0.625
  }

  .form-control:focus {
    box-shadow: none;
    border-color: #111010;
    box-shadow: #000 0 0 0 1px;
    background-color: #ffffff;
  }

  textarea.form-control {
    height: 8.75rem; //14rem * 0.625
    padding-top: 1.125rem; //1.8rem * 0.625
  }

  .form-group {
    margin-bottom: 1.875rem; //3rem * 0.625
  }

  .required {
    color: #00b0a8;
  }

  a {
    -webkit-transition: color 0.4s ease-in-out,
      background-color 0.4s ease-in-out, border 0.4s ease-in-out,
      opacity 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
    transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out,
      border 0.4s ease-in-out, opacity 0.4s ease-in-out,
      -webkit-transform 0.4s ease-in-out;
    transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out,
      transform 0.4s ease-in-out, border 0.4s ease-in-out,
      opacity 0.4s ease-in-out;
    transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out,
      transform 0.4s ease-in-out, border 0.4s ease-in-out,
      opacity 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
    text-decoration: none;
  }

  a:focus,
  a:hover {
    outline: 0;
    text-decoration: none;
  }

  label {
    margin-left: 0.875rem; //1.4rem * 0.625
    margin-bottom: 0.4375rem; //0.7rem * 0.625
    color: #707070;
    line-height: 1;
  }

  .btn {
    -webkit-transition: color 0.4s ease-in-out,
      background-color 0.4s ease-in-out, border 0.4s ease-in-out,
      opacity 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
    transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out,
      border 0.4s ease-in-out, opacity 0.4s ease-in-out,
      -webkit-transform 0.4s ease-in-out;
    transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out,
      transform 0.4s ease-in-out, border 0.4s ease-in-out,
      opacity 0.4s ease-in-out;
    transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out,
      transform 0.4s ease-in-out, border 0.4s ease-in-out,
      opacity 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
    padding: 0.875rem 2.25rem; //1.4rem * 0.625, 3.6rem * 0.625
    font-size: 1rem; //1.6rem * 0.625
    border-radius: 0.9375rem; //1.5rem * 0.625
    line-height: 1;
    font-weight: bold;
    text-transform: capitalize;
  }

  .btn-gradient {
    background: #111010;
    color: #ffffff;
    border: none;
    position: relative;
    z-index: 1;
  }

  .btn-gradient:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    background: -webkit-linear-gradient(
      180.95deg,
      #111010 0.81%,
      #0a4f4b 50.18%,
      #00b0a8 97.47%
    );
    background: linear-gradient(
      269.05deg,
      #111010 0.81%,
      #0a4f4b 50.18%,
      #00b0a8 97.47%
    );
    -webkit-transition: opacity 0.4s ease-in-out;
    transition: opacity 0.4s ease-in-out;
    border-radius: 0.9375rem; //1.5rem * 0.625
  }

  .btn-gradient:hover {
    background: #111010;
    color: #ffffff;
  }

  .btn-gradient:hover:before {
    opacity: 0;
  }

  .btn-white {
    background-color: #ffffff;
    color: #111010;
  }

  .btn-white:hover {
    background-color: #ffffff;
    color: #111010;
  }

  .btn-group-sm > .btn,
  .btn-sm {
    padding: 0.75rem 2.25rem; //1.2rem * 0.625, 3.6rem * 0.625
    --bs-btn-font-size: 0.875rem; //1.4rem * 0.625
  }

  .bgImg,
  .parallaxBgImg,
  .heroBannerParallaxImg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .parallaxBgImg,
  .heroBannerParallaxImg {
    bottom: -100px; //-100px * 0.625
  }

  .gutters-end-0 > *:last-child {
    margin-bottom: 0 !important;
  }

  .site-header {
    padding: 0.625rem 0; //1rem * 0.625
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 999;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 4.6875rem; //7.5rem * 0.625
  }

  .site-header .site-logo {
    display: block;
    width: 10.0625rem; //16.1rem * 0.625
  }

  .site-header .site-logo:hover {
    opacity: 0.7;
  }

  .site-header .header-login-btn {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: baseline;
    -webkit-align-items: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
    gap: 0.625rem; //1rem * 0.625
  }

  .site-header .header-login-btn .icon-arrow-right {
    font-size: 0.7em;
  }

  .site-header .header-login-btn:hover {
    background-color: #111010;
    color: #ffffff;
  }

  .hero-banner {
    position: relative;
    z-index: 1;
    color: #ffffff;
    margin-bottom: 4.6875rem; //7.5rem * 0.625
    padding-top: 4.6875rem; //7.5rem * 0.625
    background-color: #111010;
    padding-bottom: 1px;
  }

  .hero-banner > .bgImg {
    bottom: 5rem; //8rem * 0.625
  }

  .hero-banner:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -2;
    background: -webkit-linear-gradient(
      13.84deg,
      #00b0a8 13.66%,
      rgba(78, 78, 78, 0) 41.89%,
      #111010 86.01%
    );
    background: linear-gradient(
      76.16deg,
      #00b0a8 13.66%,
      rgba(78, 78, 78, 0) 41.89%,
      #111010 86.01%
    );
    -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
    -ms-transform: matrix(-1, 0, 0, 1, 0, 0);
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  .hero-banner .floating-pattern-bg {
    background-repeat: repeat;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -3;
    mix-blend-mode: plus-darker;
    opacity: 0.05;
  }

  .hero-banner .inner-container-fluid {
    min-height: 48.75rem; //78rem * 0.625
    box-shadow: 0 1.25rem 2.5rem rgba(0, 0, 0, 0.25); //2rem * 0.625, 4rem * 0.625
    padding: 3.125rem 1.25rem; //5rem * 0.625, 2rem * 0.625
    position: relative;
    z-index: 1;
    border-radius: 1.25rem; //2rem * 0.625
    overflow: hidden;
  }

  .hero-banner .content-holder {
    max-width: 33.875rem; //54.2rem * 0.625
    margin-left: auto;
    margin-right: auto;
    font-size: 1rem; //1.6rem * 0.625
    line-height: 1.5;
  }

  .hero-banner .content-holder h1 {
    color: inherit;
  }

  .hero-banner .content-holder h4 {
    line-height: inherit;
    font-weight: bold;
    color: #44fffb;
    margin-bottom: 0;
  }

  .section-heading {
    text-align: center;
    font-size: 1rem; //1.6rem * 0.625
    line-height: 1.5;
    font-weight: 500;
    margin-bottom: 2.5rem; //4rem * 0.625
  }

  .section-heading > *:last-child {
    margin-bottom: 0;
  }

  .advantage-section {
    margin-bottom: 4.6875rem; //7.5rem * 0.625
  }

  .advantage-section .section-heading {
    font-size: 1.125rem; //1.8rem * 0.625
  }

  .advantage-section .section-heading p {
    max-width: 20.625rem; //33rem * 0.625
    margin-left: auto;
    margin-right: auto;
  }

  .advantage-section .outer-js-advantage-image-slider {
    position: relative;
    margin-bottom: 1.25rem; //2rem * 0.625
  }

  .advantage-section .outer-js-advantage-image-slider .swiper-button-prev,
  .advantage-section
    .outer-js-advantage-image-slider
    .swiper-rtl
    .swiper-button-next {
    left: -0.625rem; //-1rem * 0.625
  }

  .advantage-section .outer-js-advantage-image-slider .swiper-button-next,
  .advantage-section
    .outer-js-advantage-image-slider
    .swiper-rtl
    .swiper-button-prev {
    right: -0.625rem; //-1rem * 0.625
  }

  .advantage-section .js-advantage-image-slider .swiper-slide img {
    border: 0.1875rem solid #111010; //0.3rem * 0.625
    border-radius: 1.25rem; //2rem * 0.625
  }

  .advantage-section .js-advantage-thumb-slider .swiper-slide {
    padding-left: 1.25rem; //2rem * 0.625
    border-left: 2px solid rgba(17, 16, 16, 0.25);
    width: 14.375rem; //23rem * 0.625
    height: auto;
    position: relative;
    cursor: pointer;
  }

  .advantage-section .js-advantage-thumb-slider .swiper-slide:before {
    content: "";
    position: absolute;
    left: -0.125rem; //-0.2rem * 0.625
    top: 0;
    height: 2.3125rem; //3.7rem * 0.625
    width: 0.125rem; //0.2rem * 0.625
    background-color: #00b0a8;
    z-index: 1;
    opacity: 0;
    -webkit-transition: opacity 0.4s ease-out;
    transition: opacity 0.4s ease-out;
  }

  .advantage-section
    .js-advantage-thumb-slider
    .swiper-slide.swiper-slide-thumb-active:before {
    opacity: 1;
  }

  .advantage-section
    .js-advantage-thumb-slider
    .swiper-slide-thumb-active
    .slide-item-count {
    background-color: #111010;
    color: #ffffff;
  }

  .advantage-section .js-advantage-thumb-slider .swiper-slide > *:last-child {
    margin-bottom: 0;
  }

  .advantage-section
    .js-advantage-thumb-slider
    .swiper-slide
    .slide-item-count {
    height: 2.125rem; //3.4rem * 0.625
    width: 2.125rem; //3.4rem * 0.625
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    border: 1px solid rgba(17, 16, 16, 0.25);
    border-radius: 50%;
    margin-bottom: 0.625rem; //1rem * 0.625
    font-size: 0.875rem; //1.4rem * 0.625
    -webkit-transition: background-color 0.4s ease-out, color 0.4s ease-out;
    transition: background-color 0.4s ease-out, color 0.4s ease-out;
  }

  .swiper {
    --swiper-navigation-size: 3rem; //4.8rem * 0.625
  }

  .swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after {
    content: "\e900";
    font-family: "icomoon", sans-serif;
  }

  .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after {
    content: "\e901";
    font-family: "icomoon", sans-serif;
  }

  .swiper-button-next,
  .swiper-button-prev {
    width: var(--swiper-navigation-size);
    height: var(--swiper-navigation-size);
    background-color: #ffffff;
    color: #111010;
    border-radius: 50%;
    box-shadow: 0 0.125rem 0.25rem rgba(16, 36, 70, 0.1); //0.2rem * 0.625, 0.4rem * 0.625
    padding: 0;
    -webkit-transition: background-color 0.4s ease-in-out,
      color 0.4s ease-in-out;
    transition: background-color 0.4s ease-in-out, color 0.4s ease-in-out;
  }

  .swiper-button-next:hover,
  .swiper-button-prev:hover {
    background-color: #111010;
    color: #ffffff;
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 0.625rem; //1rem * 0.625
  }

  .sleeping-card-section {
    position: relative;
    z-index: 1;
  }

  .sleeping-card-section .section-heading .swiper-buttons {
    margin-top: 1.5625rem; //2.5rem * 0.625
  }

  .sleeping-card-section .floating-vector {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    -webkit-transform: matrix(-1, 0, 0, 1, 0, 0) translateY(50%); //50% * 0.625
    -ms-transform: matrix(-1, 0, 0, 1, 0, 0) translateY(50%); //50% * 0.625
    transform: matrix(-1, 0, 0, 1, 0, 0) translateY(50%); //50% * 0.625
  }

  .sleeping-card-section .swiper .card {
    min-height: 25rem; //40rem * 0.625
    border: none;
    background: none;
    height: auto;
  }

  .sleeping-card-section .swiper .card__box {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    padding: 3.125rem 2.875rem 3.125rem 2.375rem; //5rem * 0.625, 4.6rem * 0.625, 5rem * 0.625, 3.8rem * 0.625
    border-radius: 0.625rem; //1rem * 0.625
    position: relative;
    z-index: 1;
    overflow: hidden;
    border-radius: 0.625rem; //1rem * 0.625
  }

  .sleeping-card-section .swiper .card:nth-child(3n + 1) .card__box {
    background-color: #111010;
    color: #ffffff;
  }

  .sleeping-card-section .swiper .card:nth-child(3n + 1) .ico-holder {
    color: #000000;
  }

  .sleeping-card-section .swiper .card:nth-child(3n + 2) .card__box {
    background-color: #181818;
    color: #ffffff;
    z-index: 2;
  }

  .sleeping-card-section .swiper .card:nth-child(3n + 2) .ico-holder {
    color: #a0a0a0;
  }

  .sleeping-card-section .swiper .card:nth-child(3n + 3) .card__box {
    background-color: #00b0a8;
    color: #111010;
  }

  .sleeping-card-section .swiper .card:nth-child(3n + 3) .ico-holder {
    color: #004340;
  }

  .sleeping-card-section .swiper .card .ico-holder {
    margin-bottom: 0.625rem; //1rem * 0.625
    font-size: 1.25rem; //2rem * 0.625
  }

  .sleeping-card-section .swiper .card h3 {
    color: inherit;
  }

  .sleeping-card-section .swiper .card .floating-bottom-img {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -3;
    opacity: 0.25;
    text-align: center;
    -webkit-transform: translateY(0.5625rem); //9% * 0.625
    -ms-transform: translateY(0.5625rem); //9% * 0.625
    transform: translateY(0.5625rem); //9% * 0.625
  }

  .sleeping-card-section .swiper .card .floating-bottom-img img {
    height: 14.625rem; //23.4rem * 0.625
    margin: 0 auto;
  }

  .sleeping-card-section + .little-details-section {
    margin-top: -0.625rem; //-1rem * 0.625
    position: relative;
    z-index: 2;
  }

  .little-details-section {
    position: relative;
    z-index: 2;
    margin-bottom: 5rem; //8rem * 0.625
  }

  .little-details-section .inner-container-fluid {
    position: relative;
    z-index: 1;
    min-height: 47.5rem; //76rem * 0.625
    padding: 5rem 0 0; //8rem * 0.625
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 1.25rem; //2rem * 0.625
    overflow: hidden;
    box-shadow: 0px 1.25rem 6.25rem rgba(25, 21, 64, 0.25); //2rem * 0.625, 10rem * 0.625
  }

  .little-details-section .columns .column {
    margin-bottom: 1.5625rem; //2.5rem * 0.625
  }

  .little-details-section .columns .column__box {
    height: 100%;
    border-bottom: 1px solid rgba(17, 16, 16, 0.25);
    padding-bottom: 1.5625rem; //2.5rem * 0.625
  }

  .little-details-section__btm {
    text-align: center;
  }

  .little-details-section__btm h3 strong {
    color: inherit;
  }

  .hd-section {
    margin-bottom: 3.125rem; //5rem * 0.625
    position: relative;
    z-index: 2;
  }

  .hd-section .section-heading {
    max-width: 10.0625rem; //16.1rem * 0.625
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
  }

  .hd-section .main-image-holder {
    margin-top: -2.5rem; //-4rem * 0.625
    margin-bottom: 3.25rem; //5.2rem * 0.625
  }

  .hd-section .tab-wrapper {
    margin-bottom: 2.1875rem; //3.5rem * 0.625
  }

  .hd-section .tab-wrapper .nav-wrapper {
    text-align: center;
    margin-bottom: 1.875rem; //3rem * 0.625
    position: relative;
  }

  .hd-section .tab-wrapper .nav-wrapper .selectBtn {
    font-weight: 500;
    font-size: 1.25rem; //2rem * 0.625
    line-height: 1;
    text-transform: capitalize;
    color: #111010;
    padding: 0.625rem 2.5rem; //1rem * 0.625, 4rem * 0.625
    height: 5rem; //8rem * 0.625
    border-radius: 6.25rem; //10rem * 0.625
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 0.8125rem; //1.3rem * 0.625
    border: 0.25rem solid #ffffff; //0.4rem * 0.625
    background-color: #e7e7e7;
    color: #111010;
    box-shadow: #d3d8e0 0px 0px 2px; //2px * 0.625
    margin-bottom: 0.3125rem; //0.5rem * 0.625
    position: relative;
    cursor: pointer;
  }

  .hd-section .tab-wrapper .nav-wrapper .selectBtn:after {
    content: "";
    width: 0.625rem; //1rem * 0.625
    height: 0.625rem; //1rem * 0.625
    -webkit-transform: translateY(-50%) rotate(45deg);
    -ms-transform: translateY(-50%) rotate(45deg);
    transform: translateY(-50%) rotate(45deg);
    border-right: 2px solid #111010; //2px * 0.625
    border-bottom: 2px solid #111010; //2px * 0.625
    -webkit-transition: 0.2s ease;
    transition: 0.2s ease;
    position: absolute;
    right: 1.875rem; //3rem * 0.625
    top: 50%;
    border-left: none;
    border-top: none;
    margin-left: 0;
  }

  .hd-section .tab-wrapper .nav-wrapper .selectBtn.toggle {
    border-radius: 3px 3px 0 0;
  }

  .hd-section .tab-wrapper .nav-wrapper .selectBtn.toggle:after {
    -webkit-transform: translateY(-50%) rotate(-135deg);
    -ms-transform: translateY(-50%) rotate(-135deg);
    transform: translateY(-50%) rotate(-135deg);
  }

  .hd-section .tab-wrapper .nav {
    background-color: #ffffff;
    padding: 0.3125rem; //0.5rem * 0.625
    border: none;
    display: block;
    border-radius: 1.875rem; //3rem * 0.625
  }

  .hd-section .tab-wrapper .nav .nav-link {
    font-weight: 500;
    font-size: 1.25rem; //2rem * 0.625
    line-height: 1;
    text-transform: capitalize;
    opacity: 0.5;
    color: #111010;
    padding: 1.5rem 2.5rem 1.375rem; //2.4rem * 0.625, 4rem * 0.625, 2.2rem * 0.625
    border-radius: 6.25rem; //10rem * 0.625
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 0.8125rem; //1.3rem * 0.625
  }

  .hd-section .tab-wrapper .nav .nav-link.active {
    background-color: #eeeeee;
    opacity: 1;
  }

  .hd-section .tab-wrapper .nav .nav-link .ico img {
    width: 1.375rem; //2.2rem * 0.625
  }

  .hd-section .tab-wrapper .tab-content .columns .column {
    color: #707070;
  }

  .hd-section .tab-wrapper .tab-content .columns .column .ico {
    font-size: 1.125rem; //1.8rem * 0.625
    color: #00b0a8;
  }

  .hd-section .tab-wrapper .tab-content .columns .column h5 {
    text-transform: capitalize;
    margin-bottom: 0.625rem; //1rem * 0.625
  }

  .hd-section__btmImg {
    position: relative;
    z-index: 1;
  }

  .hd-section__btmImg .floating-bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: -1;
    pointer-events: none;
  }

  .hd-section__btmImg .floating-bg img {
    max-height: 62.5rem; //100rem * 0.625
  }

  .w-matters-section {
    position: relative;
    z-index: 1;
    margin-bottom: 0;
  }

  .w-matters-section .floating-bg-img {
    overflow: hidden;
    margin-top: 6.25rem; //10rem * 0.625
  }

  .w-matters-section .column-heading h5 {
    font-size: 1.25rem; //2rem * 0.625
    line-height: 1.5;
    font-weight: 600;
  }

  .testimonials {
    margin-bottom: 4.375rem; //7rem * 0.625
  }

  .testimonials .section-heading .swiper-buttons {
    margin-top: 1.5625rem; //2.5rem * 0.625
  }

  .testimonials .outer-swiper {
    overflow: hidden;
  }

  .testimonials .swiper {
    padding-top: 1px;
  }

  .testimonials .swiper .testimonials-item {
    height: auto;
    width: 22rem; //35.2rem * 0.625
    max-width: calc(100% - 2.5rem); //4rem * 0.625
    border: 1px solid rgba(17, 16, 16, 0.25);
    padding: 1.6875rem; //2.7rem * 0.625
    border-radius: 0.625rem; //1rem * 0.625
    background: -webkit-linear-gradient(
      135.96deg,
      #ffffff 52.14%,
      #e7fffe 85.56%
    );
    background: linear-gradient(314.04deg, #ffffff 52.14%, #e7fffe 85.56%);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    z-index: 1;
    overflow: hidden;
  }

  .testimonials .swiper .testimonials-item.swiper-slide-active {
    border-color: #00b0a8;
    box-shadow: #00b0a8 0 0 0 1px;
  }

  .testimonials .swiper .testimonials-item.swiper-slide-active:before {
    opacity: 1;
  }

  .testimonials .swiper .testimonials-item:before {
    background: #00b0a8;
    mix-blend-mode: plus-darker;
    -webkit-filter: blur(6.25rem); //10rem * 0.625
    filter: blur(6.25rem); //10rem * 0.625
    content: "";
    height: 14.1875rem; //22.7rem * 0.625
    width: 14.25rem; //22.8rem * 0.625
    left: 0;
    top: 0;
    position: absolute;
    -webkit-transform: translate(-80%, -80%);
    -ms-transform: translate(-80%, -80%);
    transform: translate(-80%, -80%);
    z-index: -1;
    opacity: 0;
    -webkit-transition: opacity 0.4s ease-out;
    transition: opacity 0.4s ease-out;
  }

  .testimonials .swiper .testimonials-item blockquote {
    font-size: 1.5rem; //2.4rem * 0.625
    line-height: 1.4;
    font-weight: 500;
    font-family: "Aeonik Pro", sans-serif;
    margin-bottom: 1.875rem; //3rem * 0.625
  }

  .testimonials .swiper .testimonials-item .avataar-img {
    height: 3rem; //4.8rem * 0.625
    width: 3rem; //4.8rem * 0.625
    border-radius: 50%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
  }

  .testimonials .swiper .testimonials-item .avataar-name {
    margin-bottom: 0.25rem; //0.4rem * 0.625
    font-size: 1rem; //1.6rem * 0.625
    font-weight: bold;
    line-height: 1.3;
    text-transform: capitalize;
  }

  .testimonials .swiper .testimonials-item .avataar-position {
    line-height: 1.3;
    color: #707070;
    font-size: 0.875rem; //1.4rem * 0.625
    text-transform: capitalize;
  }

  .testimonials .swiper .testimonials-item__footer {
    margin-top: auto;
  }

  .testimonials .swiper .testimonials-item__footer .icon-quote-right {
    color: #00b0a8;
    font-size: 0.875rem; //1.4rem * 0.625
  }

  .swiper-buttons {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 1.875rem; //3rem * 0.625
    margin-top: 3.75rem; //6rem * 0.625
  }

  .swiper-buttons .swiper-button-next,
  .swiper-buttons .swiper-button-prev {
    position: static;
    margin: 0;
  }

  .swiper-buttons .swiper-pagination {
    position: static;
    margin: 0;
    width: auto;
  }

  .swiper-pagination-bullet {
    height: 8px; //8px * 0.625
    width: 8px; //8px * 0.625
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
    background-color: #111010;
    opacity: 0.25;
    -webkit-transition: opacity 0.4s ease-out, background-color 0.4s ease-out;
    transition: opacity 0.4s ease-out, background-color 0.4s ease-out;
    margin: 0 0.5rem !important; //0.8rem * 0.625
  }

  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  .fac-management-section {
    position: relative;
    z-index: 1;
    overflow: hidden;
    background-color: #eeeeee;
    margin-bottom: 3.125rem; //5rem * 0.625
  }

  .fac-management-section .section-heading {
    max-width: 35.3125rem; //56.5rem * 0.625
    margin-left: auto;
    margin-right: auto;
  }

  .fac-management-section .bgImg {
    background-position: top center;
    mix-blend-mode: darken;
  }

  .fac-management-section .bgImg:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 24.375rem; //39rem * 0.625
    max-height: 100%;
    z-index: 1;
    background: -webkit-linear-gradient(
      top,
      rgba(238, 238, 238, 0) 58.97%,
      #eeeeee 82.61%
    );
    background: linear-gradient(
      180deg,
      rgba(238, 238, 238, 0) 58.97%,
      #eeeeee 82.61%
    );
  }

  .fac-management-section .bgImg .bg {
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: top center;
    object-position: top center;
    opacity: 0.5;
    mix-blend-mode: darken;
    min-height: 20.625rem; //33rem * 0.625
    height: 100%;
  }

  .fac-management-section .bgImg .floating-vector {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: -2;
  }

  .fac-management-section .columns .column {
    margin-bottom: 1.875rem; //3rem * 0.625
  }

  .fac-management-section .columns .column__box {
    height: 100%;
    border-left: 1px solid rgba(17, 16, 16, 0.25);
    padding: 0 0 0 1.25rem; //2rem * 0.625
  }

  .fac-management-section .columns .column__box > *:last-child {
    margin-bottom: 0;
  }

  .fac-management-section .columns .column .column-item-count {
    height: 2.125rem; //3.4rem * 0.625
    width: 2.125rem; //3.4rem * 0.625
    background-color: #00b0a8;
    border-radius: 50%;
    color: #ffffff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 0.625rem; //1rem * 0.625
  }

  .contact-section {
    margin-bottom: 4.375rem; //7rem * 0.625
  }

  .contact-section .section-heading {
    max-width: 22.75rem; //36.5rem * 0.625
    margin-left: auto;
    margin-right: auto;
  }

  .contact-section form {
    margin-bottom: -1.875rem; //-3rem * 0.625
  }

  .contact-section form .form-check-input {
    height: 2.625rem; //4.2rem * 0.625
    width: 2.625rem; //4.2rem * 0.625
  }

  .contact-section form .form-check-input:checked {
    background-color: #00b0a8;
    border: #00b0a8;
  }

  .contact-section form .form-group-check label {
    cursor: pointer;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 0.75rem; //1.2rem * 0.625
    color: #111010;
    font-weight: 500;
  }

  .contact-section form .form-group-check label a {
    color: #00b0a8;
  }

  .contact-section form .form-group-check label a:hover {
    color: #111010;
  }

  .contact-section form .form-group-check label div {
    line-height: 1.5;
  }

  .contact-section form .btn {
    min-width: 14.25rem; //22.8rem * 0.625
    background: -webkit-linear-gradient(
      180.95deg,
      #111010 0.81%,
      #0a4f4b 50.18%,
      #00b0a8 97.47%
    );
    background: linear-gradient(
      269.05deg,
      #111010 0.81%,
      #0a4f4b 50.18%,
      #00b0a8 97.47%
    );
  }

  .contact-section form .btn:before {
    display: none;
  }

  .contact-section form .btn:hover {
    background: -webkit-linear-gradient(
      180.95deg,
      #111010 0.81%,
      #0a4f4b 50.18%,
      #00b0a8 150%
    );
    background: linear-gradient(
      269.05deg,
      #111010 0.81%,
      #0a4f4b 50.18%,
      #00b0a8 150%
    );
  }

  .site-main {
    padding-bottom: 0.0625rem; //0.1rem * 0.625
  }

  .site-footer {
    background-color: #111010;
    color: #ffffff;
    position: relative;
    z-index: 1;
    margin-top: 2.5rem; //4rem * 0.625
    padding: 6.25rem 0 2.1875rem; //10rem * 0.625, 3.5rem * 0.625
  }

  .site-footer:before,
  .site-footer:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .site-footer:before {
    background: -webkit-linear-gradient(
      top,
      rgba(17, 16, 16, 0) 52.15%,
      #111010 94.13%
    );
    background: linear-gradient(
      180deg,
      rgba(17, 16, 16, 0) 52.15%,
      #111010 94.13%
    );
    -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
    -ms-transform: matrix(-1, 0, 0, 1, 0, 0);
    transform: matrix(-1, 0, 0, 1, 0, 0);
    z-index: -1;
  }

  .site-footer:after {
    background: -webkit-linear-gradient(
      340.96deg,
      #00b0a8 6.82%,
      rgba(78, 78, 78, 0) 59.02%,
      #111010 89.1%
    );
    background: linear-gradient(
      109.04deg,
      #00b0a8 6.82%,
      rgba(78, 78, 78, 0) 59.02%,
      #111010 89.1%
    );
    -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
    -ms-transform: matrix(-1, 0, 0, 1, 0, 0);
    transform: matrix(-1, 0, 0, 1, 0, 0);
    z-index: -2;
  }

  .site-footer ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .site-footer a {
    color: #00b0a8;
  }

  .site-footer .floating-symbols {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    -webkit-transform: translateY(calc(-50% + 1.25px)); //50% * 0.625
    -ms-transform: translateY(calc(-50% + 1.25px)); //50% * 0.625
    transform: translateY(calc(-50% + 1.25px)); //2px * 0.625
  }

  .site-footer .floating-pattern-bg {
    background-repeat: repeat;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -3;
    mix-blend-mode: plus-darker;
    opacity: 0.05;
  }

  .site-footer h1,
  .site-footer h2,
  .site-footer h3,
  .site-footer h4,
  .site-footer h5,
  .site-footer h6 {
    color: inherit;
  }

  .site-footer .footer-contact-info {
    max-width: 48.125rem; //77rem * 0.625
    margin: 0 auto 1.5625rem; //2.5rem * 0.625
  }

  .site-footer .footer-contact-info .column .footer-widget-title {
    color: #707070;
    font-size: 0.875rem; //1.4rem * 0.625
    text-transform: uppercase;
    margin-bottom: 0.625rem; //1rem * 0.625
  }

  .site-footer .footer-contact-info .column ul li {
    line-height: 1.5;
    font-weight: 500;
    color: #ffffff;
    font-size: 1.125rem; //1.8rem * 0.625
  }

  .site-footer .footer-contact-info .column ul li:not(:last-child) {
    margin-bottom: 0.3125rem; //0.5rem * 0.625
  }

  .site-footer .footer-contact-info .column ul li a {
    color: inherit;
  }

  .site-footer .footer-contact-info .column ul li a:hover {
    text-decoration: underline;
    color: #00b0a8;
  }

  .site-footer__btm .site-logo {
    display: block;
    width: 10.0625rem; //16.1rem * 0.625
  }

  .site-footer__btm .site-logo:hover {
    opacity: 0.7;
  }

  .site-footer__btm .copyright {
    font-size: 0.875rem; //1.4rem * 0.625
    font-weight: 500;
  }

  .site-footer .footer-inline-links {
    font-size: 1.125rem; //1.8rem * 0.625
    font-weight: 500;
  }

  .site-footer .footer-inline-links .nav {
    gap: 1.875rem; //3rem * 0.625
  }

  .site-footer .footer-inline-links .nav li a {
    color: #ffffff;
    text-transform: capitalize;
  }

  .site-footer .footer-inline-links .nav li a:hover {
    text-decoration: underline;
    color: #00b0a8;
  }

  .site-footer .inline-social-icons {
    font-size: 1.125rem; //1.8rem * 0.625
    font-weight: 500;
  }

  .site-footer .inline-social-icons .nav {
    gap: 1.875rem; //3rem * 0.625
  }

  .site-footer .inline-social-icons .nav li a {
    color: #ffffff;
    text-transform: capitalize;
  }

  .site-footer .inline-social-icons .nav li a:hover {
    text-decoration: underline;
    color: #00b0a8;
  }

  @media (min-width: 768px) {
    html {
      font-size: 29.6875%; // 47.5% * 0.625
    }

    h1 {
      font-size: 4rem; // 6.4rem * 0.625
      margin-bottom: 0.9375rem; // 1.5rem * 0.625
    }

    h2 {
      font-size: 3rem; //4.8rem * 0.625
      margin-bottom: 0.9375rem; //1.5rem * 0.625
    }

    h3 {
      font-size: 2rem; //3.2rem * 0.625
    }

    h4 {
      font-size: 1.5rem; //2.4rem * 0.625
    }

    h5 {
      font-size: 1.125rem; //1.8rem * 0.625
    }

    label {
      margin-bottom: 0.625rem; //1rem * 0.625
    }

    .btn {
      padding: 1.0625rem 3rem; //1.7rem * 0.625, 4.8rem * 0.625
    }

    .site-header {
      height: 6.25rem; //10rem * 0.625
    }

    .hero-banner {
      padding-top: 6.25rem; //10rem * 0.625
      margin-bottom: 15rem; //24rem * 0.625
    }

    .hero-banner .inner-container-fluid {
      margin-bottom: -5rem; //-8rem * 0.625
      padding: 6.25rem; //10rem * 0.625
    }

    .hero-banner .content-holder {
      font-size: 1.125rem; //1.8rem * 0.625
    }

    .section-heading {
      font-size: 1.125rem; //1.8rem * 0.625
      margin-bottom: 3.75rem; //6rem * 0.625
    }

    .advantage-section {
      margin-bottom: 10rem; //16rem * 0.625
    }

    .advantage-section .outer-js-advantage-image-slider {
      margin-bottom: 1.875rem; //3rem * 0.625
    }

    .advantage-section .outer-js-advantage-image-slider .swiper-button-prev,
    .advantage-section
      .outer-js-advantage-image-slider
      .swiper-rtl
      .swiper-button-next {
      left: 1.5625rem; //2.5rem * 0.625
    }

    .advantage-section .outer-js-advantage-image-slider .swiper-button-next,
    .advantage-section
      .outer-js-advantage-image-slider
      .swiper-rtl
      .swiper-button-prev {
      right: 1.5625rem; //2.5rem * 0.625
    }

    .advantage-section .js-advantage-image-slider .swiper-slide img {
      border: 0.3125rem solid #111010; //0.5rem * 0.625
    }

    .sleeping-card-section .inner-container {
      max-width: 57.5rem; //92rem * 0.625
      margin-left: auto;
      margin-right: auto;
    }

    .sleeping-card-section .swiper .swiper-wrapper {
      -webkit-transform: initial !important;
      -ms-transform: initial !important;
      transform: initial !important;
      cursor: auto !important;
      pointer-events: none !important;
    }

    .sleeping-card-section .swiper .swiper-wrapper .card {
      width: 33.33% !important;
    }

    .sleeping-card-section .swiper .swiper-wrapper .card:nth-child(3n + 1) {
      bottom: -3.125rem !important; //-5rem * 0.625
      right: -2.5rem !important; //-4rem * 0.625
      -webkit-transform: rotate(-10deg) !important;
      -ms-transform: rotate(-10deg) !important;
      transform: rotate(-10deg) !important;
    }

    .sleeping-card-section .swiper .swiper-wrapper .card:nth-child(3n + 2) {
      z-index: 2 !important;
    }

    .sleeping-card-section .swiper .swiper-wrapper .card:nth-child(3n + 3) {
      bottom: -3.125rem !important; //-5rem * 0.625
      left: -2.5rem !important; //-4rem * 0.625
      -webkit-transform: rotate(10deg) !important;
      -ms-transform: rotate(10deg) !important;
      transform: rotate(10deg) !important;
    }

    .sleeping-card-section + .little-details-section {
      margin-top: -0.75rem; //-1.2rem * 0.625
    }

    .little-details-section {
      margin-bottom: 2.5rem; //4rem * 0.625
    }

    .little-details-section .inner-container-fluid {
      padding: 7.5rem 0; //12rem * 0.625
    }

    .little-details-section .columns .column {
      margin-bottom: 3.75rem; //6rem * 0.625
    }

    .little-details-section .columns .column__box {
      padding-bottom: 3.125rem; //5rem * 0.625
    }

    .hd-section {
      margin-bottom: 7.1875rem; //11.5rem * 0.625
    }

    .hd-section .section-heading {
      max-width: 16.25rem; //26rem * 0.625
    }

    .hd-section .main-image-holder {
      margin-top: -6.25rem; //-10rem * 0.625
    }

    .hd-section .inner-container {
      max-width: 47.5rem; //76.2rem * 0.625
      margin-left: auto;
      margin-right: auto;
    }

    /*     .hd-section .tab-wrapper .nav-wrapper {
      margin-bottom: 3.75rem; //6rem * 0.625
    } */

    .hd-section .tab-wrapper .dropdown-menu {
      all: unset;
    }

    .hd-section .tab-wrapper .nav {
      display: -webkit-inline-box !important;
      display: -webkit-inline-flex !important;
      display: -ms-inline-flexbox !important;
      display: inline-flex !important;
      border-radius: 6.25rem; //10rem * 0.625
    }

    .hd-section .tab-wrapper .nav .nav-item:not(:last-child) {
      margin-right: 0.75rem; //1.2rem * 0.625
    }

    .hd-section .tab-wrapper .tab-content .columns {
      --bs-gutter-x: 2.5rem; //4rem * 0.625
    }

    .hd-section .tab-wrapper .tab-content .columns .column:not(:last-child) {
      border-right: 1px solid #d3d8e0;
    }

    .hd-section .tab-wrapper .tab-content .columns .column h5 {
      margin-bottom: 0.9375rem; //1.5rem * 0.625
    }

    .w-matters-section {
      margin-bottom: 6.25rem; //10rem * 0.625
    }

    .w-matters-section .floating-bg-img {
      margin-top: -6.25rem; //-10rem * 0.625
    }

    .w-matters-section .inner-container {
      max-width: 47.5rem; //76.2rem * 0.625
      margin-left: auto;
      margin-right: auto;
    }

    .w-matters-section .column-heading h5 {
      padding-right: 4.375rem; //7rem * 0.625
    }

    .testimonials {
      margin-bottom: 10rem; //16rem * 0.625
    }

    .testimonials .swiper .testimonials-item {
      width: 35.3125rem; //56.5rem * 0.625
      padding: 2.5rem; //4rem * 0.625
    }

    .testimonials .swiper .testimonials-item .avataar-position {
      font-size: 1rem; //1.6rem * 0.625
    }

    .fac-management-section {
      margin-bottom: 8.125rem; //13rem * 0.625
    }

    .fac-management-section .bgImg:after {
      background: -webkit-linear-gradient(
        top,
        rgba(238, 238, 238, 0) 0%,
        #eeeeee 55.01%
      );
      background: linear-gradient(
        180deg,
        rgba(238, 238, 238, 0) 0%,
        #eeeeee 55.01%
      );
    }

    .fac-management-section .columns .column__box {
      padding: 0 1.25rem; //2rem * 0.625
    }

    .contact-section {
      margin-bottom: 10rem; //16rem * 0.625
    }

    .contact-section .inner-container {
      max-width: 47.5rem; //76.2rem * 0.625
      margin-left: auto;
      margin-right: auto;
    }

    .site-footer {
      margin-top: 8.125rem; //13rem * 0.625
      padding: 17.5rem 0 7.5rem; //28rem * 0.625, 12rem * 0.625
    }

    .site-footer .floating-symbols {
      -webkit-transform: translateY(-6.625rem); //-10.6rem * 0.625
      -ms-transform: translateY(-6.625rem); //-10.6rem * 0.625
      transform: translateY(-6.625rem); //-10.6rem * 0.625
    }

    .site-footer .floating-symbols img {
      height: 13.75rem; //22rem * 0.625
    }

    .site-footer .section-heading {
      margin-bottom: 4.375rem; //7rem * 0.625
    }

    .site-footer .footer-contact-info {
      margin: 0 auto 5rem; //8rem * 0.625
    }

    .site-footer .footer-contact-info .column {
      max-width: 33.33%;
    }

    .site-footer .footer-inline-links {
      font-size: 0.875rem; //1.4rem * 0.625
    }

    .site-footer .footer-inline-links .nav {
      gap: 1.25rem; //2rem * 0.625
    }

    .site-footer .inline-social-icons {
      font-size: 0.875rem; //1.4rem * 0.625
    }

    .site-footer .inline-social-icons .nav {
      gap: 1.25rem; //2rem * 0.625
    }
  }

  @media (min-width: 992px) {
    html {
      font-size: 32.8125%; // 52.5% * 0.625
    }

    .advantage-section .inner-container {
      max-width: 88.8%;
      margin-left: auto;
      margin-right: auto;
    }

    .advantage-section .outer-js-advantage-image-slider .swiper-button-prev,
    .advantage-section
      .outer-js-advantage-image-slider
      .swiper-rtl
      .swiper-button-next {
      -webkit-transform: translateX(-100%);
      -ms-transform: translateX(-100%);
      transform: translateX(-100%);
      left: -2.1875rem; //-3.5rem * 0.625
    }

    .advantage-section .outer-js-advantage-image-slider .swiper-button-next,
    .advantage-section
      .outer-js-advantage-image-slider
      .swiper-rtl
      .swiper-button-prev {
      -webkit-transform: translateX(100%);
      -ms-transform: translateX(100%);
      transform: translateX(100%);
      right: -2.1875rem; //-3.5rem * 0.625
    }

    .sleeping-card-section + .little-details-section {
      margin-top: -0.4375rem; //-0.7rem * 0.625
    }

    .hd-section .main-image-holder {
      margin-top: -8.125rem; //-13rem * 0.625
    }

    .fac-management-section .inner-container {
      max-width: 82.75%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media (min-width: 1200px) {
    html {
      font-size: 35.9375%; // 57.5% * 0.625
    }

    .container-fluid {
      padding-left: 3rem; //4.8rem * 0.625
      padding-right: 3rem; //4.8rem * 0.625
    }

    .container {
      --bs-gutter-x: 6.25rem; //10rem * 0.625
      max-width: 78.75rem; //126rem * 0.625
    }

    .advantage-section .outer-js-advantage-image-slider .swiper-button-prev,
    .advantage-section
      .outer-js-advantage-image-slider
      .swiper-rtl
      .swiper-button-next {
      left: -2.8125rem; //-4.5rem * 0.625
    }

    .advantage-section .outer-js-advantage-image-slider .swiper-button-next,
    .advantage-section
      .outer-js-advantage-image-slider
      .swiper-rtl
      .swiper-button-prev {
      right: -2.8125rem; //-4.5rem * 0.625
    }

    .site-footer {
      padding: 20.8125rem 0 7.5rem; //33.5rem * 0.625, 12rem * 0.625
    }

    .site-footer .floating-symbols {
      -webkit-transform: translateY(-7.875rem); //-12.6rem * 0.625
      -ms-transform: translateY(-7.875rem); //-12.6rem * 0.625
      transform: translateY(-7.875rem); //-12.6rem * 0.625
    }

    .site-footer .floating-symbols img {
      height: 16.25rem; //26rem * 0.625
    }
  }

  @media (min-width: 1440px) {
    html {
      font-size: 39.0625%; // 62.5% * 0.625
    }
  }

  @media (min-width: 1792px) {
    html {
      font-size: 11px; //11px * 0.625
    }
  }

  @media (max-width: 767px) {
 /*    .container,
    .container-fluid,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
      --bs-gutter-x: 1.25rem; //2rem * 0.625
    } */

    .site-header .header-login-btn {
      padding: 0.625rem 2.25rem; //1rem * 0.625, 3.6rem * 0.625
      font-size: 0.875rem; //1.4rem * 0.625
    }

    .hero-banner {
      padding-bottom: 2.8125rem; //4.5rem * 0.625
    }

    .hero-banner .content-holder p {
      margin-bottom: 0.9375rem; //1.5rem * 0.625
    }

    .section-heading p {
      margin-bottom: 0.9375rem; //1.5rem * 0.625
    }

    .advantage-section .outer-js-advantage-image-slider {
      margin-left: 0.625rem; //1rem * 0.625
      margin-right: 0.625rem; //1rem * 0.625
    }

    .little-details-section {
      text-align: center;
    }

    .little-details-section .bgImg {
      -webkit-transform: initial !important;
      -ms-transform: initial !important;
      transform: initial !important;
      bottom: 0;
    }

    .little-details-section .section-heading {
      margin-bottom: 1.5625rem; //2.5rem * 0.625
    }

    .little-details-section__btm h3 {
      margin-bottom: 1.5625rem; //2.5rem * 0.625
    }

    .hd-section .inner-container {
      padding-left: 0.625rem; //1rem * 0.625
      padding-right: 0.625rem; //1rem * 0.625
    }

    .hd-section .tab-wrapper .dropdown-menu {
      background: none;
      border: none;
      box-shadow: none;
      width: 100%;
    }

    .hd-section .tab-wrapper .nav .nav-link {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      font-size: 1rem; //1.6rem * 0.625
      padding: 0.8125rem 2.5rem; //1.3rem * 0.625, 4rem * 0.625
    }

    .hd-section .tab-wrapper .tab-content .columns {
      --bs-gutter-x: 0;
    }

    .hd-section .tab-wrapper .tab-content .columns .column:not(:last-child) {
      border-bottom: 1px solid #d3d8e0;
      padding-bottom: 1.25rem; //2rem * 0.625
      margin-bottom: 1.25rem; //2rem * 0.625
    }

    .w-matters-section {
      text-align: center;
    }

    .w-matters-section .section-heading {
      margin-bottom: 0.9375rem; //1.5rem * 0.625
    }

    .w-matters-section .column-heading {
      margin-bottom: 0.9375rem; //1.5rem * 0.625
    }

    .testimonials .section-heading {
      margin-bottom: 1.5625rem; //2.5rem * 0.625
    }

    .fac-management-section .bgImg {
      top: 0;
      bottom: auto;
      -webkit-transition: initial !important;
      transition: initial !important;
    }

    .fac-management-section .outer-columns {
      position: relative;
      z-index: 1;
    }

    .contact-section form .btn {
      width: 100%;
    }

    .site-footer {
      text-align: center;
    }

    .site-footer .floating-symbols img {
      max-height: 12.5rem; //20rem * 0.625
    }

    .site-footer .section-heading h2 {
      font-size: 2.5rem; //4rem * 0.625
    }

    .site-footer .footer-contact-info .column {
      margin-bottom: 1.5625rem; //2.5rem * 0.625
    }

    .site-footer__btm .site-logo {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 0.9375rem; //1.5rem * 0.625
    }

    .site-footer .column-site-logo-and-copyright {
      margin-top: 3.4375rem; //5.5rem * 0.625
    }
  }

  @media (max-width: 575px) {
    .sleeping-card-section .container {
      padding-left: 0;
      padding-right: 0;
    }

    .little-details-section .inner-container-fluid {
      margin-left: -0.625rem; //-1rem * 0.625
      margin-right: -0.625rem; //-1rem * 0.625
    }
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    .w-matters-section .floating-bg-img img {
      min-width: 87.5rem; //140rem * 0.625
      position: relative;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
    }
  }
}
